$desktop: 1024px;

html {
  overflow: hidden;
}
.App {
  width: 100vw;
  height: 100vh;
  background: #b9dc85;
  background: linear-gradient(
    0deg,
    rgba(139, 190, 119, 1) 0%,
    rgba(185, 220, 133, 1) 50%,
    rgba(185, 220, 133, 1) 50%,
    rgba(0, 0, 0, 1) 90%,
    rgba(0, 0, 0, 1) 100%
  );
  background-size: 100% calc(100% + 100vh);
  background-position-y: -100vh;

  transition: 0.51s;
}
.App--is-dark {
  transition: 1s;
  background-size: 100% calc(900%);
  background-position-y: 0; //-100vh;

  // background-size: 100% 900%;
  // background: black;
  // background: linear-gradient(
  //   0deg,
  //   rgb(33, 33, 33) 0%,
  //   rgb(0, 0, 0) 20%,
  //   rgb(0, 0, 0) 100%
  // );
}

.swap-brain-page {
  position: absolute;
  pointer-events: none;
  left: 0%;
  top: 0%;
  z-index: 999999;
  .button {
    pointer-events: all;
  }
}

.page-container {
  position: absolute;
  // background: red;
  z-index: 999;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.ui-sticky-prev {
  position: absolute;
  pointer-events: all;
  left: 0;
  bottom: 0;
  z-index: 999999;
}
.ui-sticky-next {
  position: absolute;
  pointer-events: all;
  right: 0;
  bottom: 0;
  z-index: 999999;
}

.button {
  font-family: "NeueRational";
  border: 0;
  background: transparent;
  background: #2d6025;
  color: white;
  text-transform: uppercase;
  white-space: nowrap;

  font-size: 15px;
  border-radius: 40px;
  padding: 10px;
  padding-left: 30px;
  padding-right: 30px;
  margin: 20px;
  cursor: pointer;

  @media only screen and (min-width: $desktop) {
    font-size: 20px;
    border-radius: 40px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    margin: 10px;
  }
}

.button--alt {
  background: red;
  color: black;
}
.button--white {
  background: white;
  color: black;
}

.ui-get-ready {
  // position: absolute;
  // top: calc(50% - 10px);
  // left: 50%;
  .ui-match-title {
    white-space: nowrap;
    transform: translate(-50%, -50%);
    text-transform: uppercase;
    color: #dcedc1;
    font-family: "NeueRational";
    // font-style: normal;
    // font-weight: 100;
    font-size: 110px;
    line-height: 0.75;
    text-align: center;
    margin: 0%;
    padding: 0;
    @media only screen and (min-width: $desktop) {
      font-size: 210px;
      line-height: 0.75;
    }
  }
}
.warning {
  color: red;
}
.game-states {
  display: flex;
}
.game-state {
  overflow-y: scroll;
  width: 400px;
  height: 300px;
  order: 2;
}
.game-state--original {
  order: 1;
}
@import "./Animate.scss";

// ------
// .animate-enter {
//   opacity: 0;
//   transform: translate(0, 25px);
//   z-index: 1;
// }
// .animate-enter.animate-enter-active {
//   opacity: 1;
//   transform: translate(0, 0);

//   transition: opacity 250ms ease-out, transform 300ms ease;
// }
// .animate-exit {
//   opacity: 1;
//   transform: translate(0, 0);
// }
// .animate-exit.animate-exit-active {
//   opacity: 0;
//   transform: translate(0, 30px);

//   transition: opacity 250ms ease-out, transform 300ms ease;
// }
